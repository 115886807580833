
  import { Component, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';

  import { isRequired, isEmail } from '@/helpers/validations';

  const appStore = namespace('app');

  @Component
  export default class RemindPage extends Vue {
    email = '';
    isValid = false;

    @appStore.Action
    resetPassword!: (email: string) => Promise<void>;

    isRequired(string: string): boolean {
      return isRequired(string);
    }

    isEmail(email: string): boolean {
      return Boolean(email && isEmail(email));
    } 
  }
